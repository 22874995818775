var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-sidebar', {
    attrs: {
      "id": "add-new-user-sidebar",
      "visible": _vm.isAddNewSidebarActive,
      "bg-variant": "white",
      "sidebar-class": "sidebar-lg",
      "shadow": "",
      "backdrop": "",
      "width": "1000px",
      "no-header": "",
      "right": ""
    },
    on: {
      "hidden": _vm.resetForm,
      "change": function change(val) {
        return _vm.$emit('update:is-add-new-sidebar-active', val);
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var hide = _ref.hide;
        return [_c('div', {
          staticClass: "d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        }, [_c('h5', {
          staticClass: "mb-0"
        }, [_vm._v(" Permintaan Pelayanan Kapal & Barang di Terminal di "), _c('code', [_vm._v("PT. Sarana Abadi Lestari Palaran")])]), _c('feather-icon', {
          staticClass: "ml-1 cursor-pointer",
          attrs: {
            "icon": "XIcon",
            "size": "16"
          },
          on: {
            "click": hide
          }
        })], 1), _c('validation-observer', {
          ref: "refFormObserver",
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var handleSubmit = _ref2.handleSubmit;
              return [_c('b-form', {
                staticClass: "p-2",
                on: {
                  "submit": function submit($event) {
                    $event.preventDefault();
                    return handleSubmit(_vm.onSubmit);
                  },
                  "reset": function reset($event) {
                    $event.preventDefault();
                    return _vm.resetForm.apply(null, arguments);
                  }
                }
              }, [_c('validation-provider', {
                attrs: {
                  "name": "Full Name",
                  "rules": "required"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(validationContext) {
                    return [_c('b-form-group', {
                      attrs: {
                        "label": "Full Name",
                        "label-for": "full-name"
                      }
                    }, [_c('b-form-input', {
                      attrs: {
                        "id": "full-name",
                        "autofocus": "",
                        "state": _vm.getValidationState(validationContext),
                        "trim": "",
                        "placeholder": "John Doe"
                      },
                      model: {
                        value: _vm.userData.fullName,
                        callback: function callback($$v) {
                          _vm.$set(_vm.userData, "fullName", $$v);
                        },
                        expression: "userData.fullName"
                      }
                    }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
                  }
                }], null, true)
              }), _c('div', {
                staticClass: "d-flex mt-2"
              }, [_c('b-button', {
                directives: [{
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: 'rgba(255, 255, 255, 0.15)',
                  expression: "'rgba(255, 255, 255, 0.15)'",
                  modifiers: {
                    "400": true
                  }
                }],
                staticClass: "mr-2",
                attrs: {
                  "variant": "primary",
                  "type": "submit"
                }
              }, [_c('feather-icon', {
                attrs: {
                  "icon": "AnchorIcon"
                }
              }), _vm._v(" Submit ")], 1), _c('b-button', {
                directives: [{
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: 'rgba(186, 191, 199, 0.15)',
                  expression: "'rgba(186, 191, 199, 0.15)'",
                  modifiers: {
                    "400": true
                  }
                }],
                attrs: {
                  "type": "button",
                  "variant": "outline-secondary"
                },
                on: {
                  "click": hide
                }
              }, [_c('feather-icon', {
                attrs: {
                  "icon": "XCircleIcon"
                }
              }), _vm._v(" Cancel ")], 1)], 1)], 1)];
            }
          }], null, true)
        })];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }