<template>
  <YoTable
    ref-specify="SpeedBoatTable"
    :api-endpoint="apiEndpoint"
    :mock-api="false"
    :title-tbl="this.$t('Dashboards') + ' SpeedBoat'"
    sub-title-tbl="Permintaan Pelayanan Kapal & Barang"
    :fields="fields"
    :new-data-template="newDataTemplate"
    :resolve-fetch-response="resolveFetchResponse"
    :resolve-fetch-url="resolveFetchUrl"
    :disable-edit="disableEdit"
    :multiple-header="multipleHeader"
    :force-disable-crud="true"
    enable-create-form="monitoring-jalur-darat-form-type-3-wizard"
    placeholder-search="LHCode"
  />
</template>

<script>
import moment from 'moment'
import YoTable from '@/views/pages/components/sungai/CompTableTBBM.vue'

export default {
  components: {
    YoTable
  },
  data() {
    return {
      apiEndpoint: 'form3_land_vehicle', //
      disableEdit: true,
      multipleHeader: {
        startFrom: this.$can('manage', 'qrcode') ? 5 : 4,
        content: [
          {
            value: 'Kegiatan',
            span: '2',
            align: 'text-center'
          }
          // {
          //   value: 'QRCODE',
          //   span: '2',
          //   align: 'text-center',
          // },
          // {
          //   value: 'CLOCK',
          //   span: '3',
          //   align: 'text-center',
          // },

          // {
          //   value: 'Kendaraan',
          //   span: '2',
          //   align: 'text-center',
          // },
          // {
          //   value: '',
          //   span: '2',
          //   align: 'text-center',
          // },
          // {
          //   value: 'Detail Bongkar (B)',
          //   span: '4',
          //   align: 'text-center',
          // },
          // {
          //   value: 'Detail Muat (M)',
          //   span: '4',
          //   align: 'text-center',
          // },
          // {
          //   value: 'SCHEDULE',
          //   span: '3',
          //   align: 'text-center',
          // },
        ],
        noEnd: false,
        endFrom: this.$can('manage', 'qrcode') ? 8 : 7
      },
      fields: [
        {
          searchModel: 'group.name',
          search: {
            type: 'text'
          },
          key: 'group.name',
          minWidth: '130px',
          label: 'GROUP',
          placeholder: '',
          sortable: true,
          input: { type: 'input-noarea', disabled: true }
        },
        // {
        //   key: 'action_form',
        //   label: 'Aksi',
        //   acl: this.$can('manage', 'qrcode') || this.$can('MI (LMP)', '') || this.$can('DAS (Cementing)', '') || this.$can('Facility (SLB)', '') || this.$can('ELNUSA (Cementing)', '') || this.$can('SAL', ''),
        // },
        {
          searchModel: 'form_status',
          search: {
            type: 'text'
          },
          key: 'show_details',
          // stickyColumn: true,
          minWidth: '150px',
          label: 'Details',
          sortable: true,
          input: { type: 'input-noarea' }
        },
        {
          searchModel: 'form_status',
          search: {
            type: 'text'
          },
          key: 'fullName',
          // stickyColumn: true,
          minWidth: '150px',
          label: 'Status',
          sortable: true,
          input: { type: 'input-noarea' }
        },
        {
          key: '2',
          label: 'Nomor DO / BL',
          sortable: true,
          input: { type: 'input-noarea' },
          searchModel: 'segmen',
          search: {
            type: 'text'
          }
        },
        {
          searchModel: 'keg_bongkar',
          search: {
            type: 'checkbox',
            option: [
              {
                title: '✓',
                value: '1'
              },
              {
                title: '✕',
                value: '0'
              }
            ]
          },
          key: '3',
          minWidth: '180px',
          verticalAlign: 'middle',
          label: 'Bongkar',
          sortable: true,
          input: {
            type: 'select',
            boolean: true,
            disabled: true
          }
        },
        {
          searchModel: 'keg_muat',
          search: {
            type: 'checkbox',
            option: [
              {
                title: '✓',
                value: '1'
              },
              {
                title: '✕',
                value: '0'
              }
            ]
          },
          key: '4',
          minWidth: '180px',
          verticalAlign: 'middle',
          label: 'Muat',
          sortable: true,
          input: {
            type: 'select',
            boolean: true,
            disabled: true
          }
        },
        {
          searchModel: 'form_status',
          search: {
            type: 'text'
          },
          key: '5',
          // stickyColumn: true,
          minWidth: '150px',
          label: 'Nama Kapal',
          sortable: true,
          input: { type: 'input-noarea' }
        }
        // {
        //   key: '6',
        //   label: 'GT',
        //   sortable: true,
        //   // stickyColumn: true,
        //   input: { type: 'input-noarea' },
        //   searchModel: 'bongkar_no_doc',
        //   search: {
        //     type: 'text',
        //   },
        // },
        // {
        //   key: '7',
        //   label: 'LOA',
        //   sortable: true,
        //   // stickyColumn: true,
        //   input: { type: 'input-noarea' },
        //   searchModel: 'muat_no_doc',
        //   search: {
        //     type: 'text',
        //   },
        // },
        // {
        //   key: '8',
        //   label: 'Volume',
        //   sortable: true,
        //   input: { type: 'input-noarea' },
        //   searchModel: 'segmen',
        //   search: {
        //     type: 'text',
        //   },
        // },

        // {
        //   searchModel: 'group.name',
        //   search: {
        //     type: 'text',
        //   },
        //   key: 'action_qrcode',
        //   minWidth: '100px',
        //   label: 'Action',
        //   sortable: true,
        //   input: { type: 'input-noarea' },
        // },

        // {
        //   searchModel: 'qrcode_status',
        //   search: {
        //     type: 'text',
        //   },
        //   key: 'qrcode_status',
        //   minWidth: '150px',
        //   label: 'QR Status',
        //   sortable: true,
        //   input: { type: 'input-noarea' },
        // },
        // {
        //   searchModel: 'clock_in_time',
        //   search: {
        //     type: 'checkbox',
        //     option: [
        //       {
        //         title: 'Ada',
        //         value: '1',
        //       },
        //     ],
        //   },
        //   key: 'clock_in_time',
        //   minWidth: '180px',
        //   verticalAlign: 'middle',
        //   label: 'CLOCK IN',
        //   sortable: true,
        //   input: {
        //     type: 'time',
        //     format: 'DD MMMM YYYY  hh:mm A',
        //     disabled: true,
        //   },
        // },
        // {
        //   searchModel: 'clock_out_time',
        //   search: {
        //     type: 'checkbox',
        //     option: [
        //       {
        //         title: 'Ada',
        //         value: '1',
        //       },
        //     ],
        //   },
        //   key: 'clock_out_time',
        //   minWidth: '180px',
        //   verticalAlign: 'middle',
        //   label: 'CLOCK OUT',
        //   sortable: true,
        //   input: {
        //     type: 'time',
        //     format: 'DD MMMM YYYY  hh:mm A',
        //     disabled: true,
        //   },
        // },
        // {
        //   searchModel: 'diff_clock',
        //   search: {
        //     type: 'text',
        //   },
        //   key: 'diff_clock',
        //   label: 'DIFF CLCOK',
        //   minWidth: '260px',
        //   sortable: true,
        //   input: { type: 'input-noarea', disabled: true },
        // },

        // {
        //   key: 'dm_land_vehicle.plat_no_pol',
        //   label: 'Plat No POL',
        //   placeholder: 'Cth: KT 1503 DR',
        //   minWidth: '150px',
        //   sortable: true,
        //   stickyColumn: true,
        //   input: { type: 'input-noarea' },
        //   searchModel: 'dm_land_vehicle.plat_no_pol',
        //   search: {
        //     type: 'text',
        //   },
        // },
        // {
        //   key: 'dm_land_vehicle.type_vehicle',
        //   label: 'Jenis Kendaraan',
        //   minWidth: '180px',
        //   sortable: true,
        //   input: { type: 'input-noarea' },
        //   searchModel: 'dm_land_vehicle.type_vehicle',
        //   search: {
        //     type: 'text',
        //   },
        // },
        // {
        //   key: 'dm_personal.full_name',

        //   label: 'Driver',
        //   placeholder: 'Sopir',
        //   minWidth: '190px',
        //   sortable: true,
        //   input: { type: 'input-noarea' },
        //   searchModel: 'dm_personal.full_name',
        //   search: {
        //     type: 'text',
        //   },
        // },
        // {
        //   key: 'dm_personal.no_ktp',
        //   label: 'No KTP',
        //   placeholder: 'No KTP',
        //   minWidth: '190px',
        //   sortable: true,
        //   input: { type: 'input-noarea' },
        //   searchModel: 'dm_personal.no_ktp',
        //   search: {
        //     type: 'text',
        //   },
        // },

        // {
        //   searchModel: 'schedule_in',
        //   search: {
        //     type: 'date',
        //   },
        //   key: 'schedule_in',
        //   label: 'SCHEDULE IN',
        //   sortable: true,
        //   input: {
        //     type: 'date',
        //     format: 'D MMMM YYYY - hh:mm A',
        //     disabled: true,
        //   },
        // },
        // {
        //   searchModel: 'schedule_out',
        //   search: {
        //     type: 'date',
        //   },
        //   key: 'schedule_out',
        //   label: 'SCHEDULE OUT',

        //   sortable: true,
        //   input: {
        //     type: 'date',
        //     format: 'D MMMM YYYY - hh:mm A',
        //     disabled: true,
        //   },
        // },

        // {
        //   searchModel: 'diff_schedule',
        //   search: {
        //     type: 'text',
        //   },
        //   key: 'diff_schedule',
        //   label: 'DIFF SCHEDULE',
        //   minWidth: '260px',
        //   sortable: true,
        //   input: { type: 'input-noarea', disabled: true },
        // },
        // {
        //   key: 'user.full_name',
        //   label: 'Created BY',
        //   sortable: true,
        //   placeholder: 'Otomatis terisi',
        //   input: { type: 'input-noarea', disabled: true },
        //   searchModel: 'user.full_name',
        //   search: {
        //     type: 'text',
        //   },
        // },
        // {
        //   searchModel: 'user.phone',
        //   search: {
        //     type: 'number',
        //   },
        //   key: 'user.phone',
        //   minWidth: '170px',
        //   label: 'Phone of Created BY',
        //   sortable: true,
        //   placeholder: 'Otomatis terisi',
        //   input: { type: 'input-noarea', disabled: true },
        // },
        // {
        //   key: 'created_at',
        //   label: 'Created AT',
        //   sortable: true,
        //   placeholder: 'Otomatis terisi',
        //   input: {
        //     type: 'date',
        //     format: 'D MMMM YYYY - hh:mm A',
        //     disabled: true,
        //   },
        //   searchModel: 'created_at',
        //   search: {
        //     type: 'date',
        //   },
        // },
        // {
        //   key: 'updated_at',
        //   label: 'Updated AT',
        //   sortable: true,
        //   placeholder: 'Otomatis terisi',
        //   input: {
        //     type: 'date',
        //     format: 'D MMMM YYYY - hh:mm A',
        //     disabled: true,
        //   },
        //   searchModel: 'updated_at',
        //   search: {
        //     type: 'date',
        //   },
        // },
        // {
        //   key: 'form_expired_at',
        //   label: 'Form Expired AT',
        //   sortable: true,
        //   placeholder: 'Otomatis terisi',
        //   input: {
        //     type: 'date',
        //     format: 'D MMMM YYYY - hh:mm A',
        //     disabled: true,
        //   },
        //   searchModel: 'form_expired_at',
        //   search: {
        //     type: 'date',
        //   },
        // },
        // {
        //   searchModel: 'emergency_action',
        //   search: {
        //     type: 'text',
        //   },
        //   key: 'emergency_action',
        //   label: 'Emergency IN OUT',
        //   sortable: true,
        //   input: { type: 'input-noarea' },
        //   acl: this.$can('manage', 'qrcode'),
        // },
      ]
    }
  },
  computed: {
    newDataTemplate() {
      return {
        // name: '',
        // route: '',
      }
    }
  },
  methods: {
    moment,
    resolveFetchResponse(response) {
      return response.data.form3_land_vehicles
    },
    resolveFetchUrl({ pagination }) {
      return {
        url: this.apiEndpoint,
        query: {
          page: pagination.currentPage - 1,
          size: pagination.selectedAmount,
          sort: 'DESC'
        }
      }
    }
  }
}
</script>
